import { useQuery } from "@vue/apollo-composable";
import gql from "graphql-tag";
import { debounce, isString } from "lodash";

interface PreviewModel {
  gid: string;
}

interface PreviewSession {
  url: string;
  session: string;
  expiry: number;
}

type BeforeCb = { (): Promise<void> };

export const usePreviewModel = (model: Ref<PreviewModel | string>) => {
  const enabled = ref(false);
  const loaded = ref(false);
  const expiry = ref(0);
  const url = ref<string>();

  let beforeCb: BeforeCb;

  const query = useQuery<{
    wsPreviewSession: PreviewSession;
  }>(
    gql`
      query ($gid: String!) {
        wsPreviewSession(gid: $gid) {
          url
          expiry
        }
      }
    `,
    () => ({ gid: isString(model.value) ? model.value : model.value.gid }),
    () => ({ enabled: enabled.value })
  );

  const openUrl: { (): void } = debounce(() => {
    if (url.value) {
      window.open(url.value, "_blank")?.focus();
    }
  }, 500);

  query.onResult((result) => {
    const data = result.data.wsPreviewSession;
    if (data) {
      loaded.value = true;
      expiry.value = data.expiry;
      url.value = data.url;
      openUrl();
    } else {
      loaded.value = false;
    }
  });

  const preview = async () => {
    if (beforeCb) {
      await beforeCb();
    }

    if (!loaded.value) {
      enabled.value = true;
    }

    if (isExpired(expiry.value)) {
      url.value = undefined;
      await query.refetch();
    }

    if (loaded.value) {
      openUrl();
    }
  };

  const onBefore = (cb: BeforeCb) => {
    beforeCb = cb;
  };

  return {
    loading: query.loading,
    preview,
    onBefore,
  };
};
